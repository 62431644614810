@mixin phone {
    @media (max-width: 499px) { @content; }
  }
// @mixin phone {
//     @media (max-width: 599px) { @content; }
//   }
  @mixin tablet {
    @media (max-width: 900px) { @content; }
  }
  @mixin tablet_landscape {
    @media (max-width: 1070px) { @content; }
  }
  @mixin desktop {
    @media (max-width: 1200px) { @content; }
  }
  @mixin big-desktop {
    @media (min-width: 1800px) { @content; }
  }