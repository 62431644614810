#chassis{
    text-transform: uppercase;
    &::placeholder{
        text-transform: capitalize!important;
    }
}
input[name="nomClient"]{
    text-transform: uppercase;
    &::placeholder{
        text-transform: capitalize!important;
    }
}