.side-menu {
    transform: translateX(-100%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 30%;
    z-index: 3;
    transition: all 0.5s ease;
    @include tablet() {
        width: 45%;
    }
    @include phone() {
        width: 100%;
    }
    ul {
        padding: 1em 2.5em;
        margin-top: 3.5em;
        list-style: none;
        li {
            font-size: $text-sm;
            font-weight: 500;
            cursor: pointer;
            margin-bottom: 0.7em;
        }
    }
    &.open {
        transform: translateX(0);
    }
}