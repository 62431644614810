.payment-list {
    .header {
        margin-bottom: 2em;
    }
    .filters {
        margin: 2em 0;
        padding: 2em;
        background-color: $light-grey;
        button {
            margin-top: 1em;
        }
    }
}