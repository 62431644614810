.payment {
  width: 70%;
  @include tablet() {
    width: 90%;
  }
  .header {
    margin-bottom: 4em;
  }
  .payment-zone {
    display: flex;
    gap: 3em;
    @include tablet_landscape() {
        flex-direction: column;
    }
    .payment-steps {
      flex: 1;
    }
    .payment-form {
      flex: 3;
    }
  }
}
