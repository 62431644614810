.payment-invoice {
  .status{
      margin-bottom: 0.9em;
      margin-top: -1em;
      p{
        text-align: left;
        font-size: 14px;
        span{
          font-weight: 600;
          font-size: 12px;
        }
        .nonvalide{
           color: #5e0505;
         
        }
        .valide{
          color: #043f04;
        }
        .rejete{
          color: #780505;
        }
        .resoumission{
          color: #a00d0d;
        }
        .resoumis{
          color: #9d0808;
        }
      }
  }
  width: 50%;
  font-size: 0.85rem;
  @include tablet_landscape() {
    width: 70%;
  }
  @include phone {
    width: 90%;
  }

  .buttons-group {
    margin-bottom: 2em;
    .left {
      .print-btn {
        margin-right: 0.5em;
      }
    }
    @include phone() {
      flex-direction: column;
      .right {
        margin-top: 0.5em;
      }
    }
  }
}
