.stepper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  // gap: 3em;
  @include tablet_landscape() {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  .step {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 1em;
    cursor: pointer;
 
    &:hover {
      .step-icon {
        background-color: $primary;
        svg {
          color: $white;
          transition: all 0.3s ease;
        }
      }
    }
    .step-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.8em;
      background-color: $light-grey;
      border-radius: 50%;
      font-size: 1.1rem;
      transition: all 0.2s ease-in-out;
      position: relative;
     
      &::after {
        content: "";
        width: 2px;
        height:8em;
        position: absolute;
        background-color: $light-grey;
        left: 50%;
        z-index: -1;
      }
      @include tablet_landscape() {
        &::after {
          display: none;
        }
      }
    }
    .step-info {
      display: flex;
      flex-direction: column;
      span {
        font-size: $text-sm;
      }
      strong {
        font-size: 0.85rem;
      }
    }
    &.step-active {
      .step-icon {
        background-color: $primary;
        svg {
          color: $white;
          transition: all 0.3s ease;
        }
        &::after {
          background-color: $primary;
        }
      }
    }
  }
}
