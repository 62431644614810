.status{
    .attente{
        font-weight: 600;
        color: #7c7a7a;
    }
    .valider{
        font-weight: 600;
        color: green;
    }
    .annuler{
        font-weight: 600;
        color: red;
    }
}