.buttons-group{
    justify-content: flex-start;
    column-gap: 5px;
}

.invoice-cmc{
    width: 100%;
    background: #e6e7e6;
    padding: 20px 20px;

    height: 100%;
    .background-img{
        width: 100%;
        &:nth-child(1)::before{
            content: "";
            position: absolute;
            top: 13em;
            //bottom: 100px; 
            left: 0;
            width: 100%; 
            height:340px;
            background-repeat: no-repeat;
            background-size: 540px 240px;
            background-position: center;
            opacity: .09; 
            z-index: 1;
            background-image: url('../../assets/images/DSD_logo.jpeg');
        }
        &:nth-child(2)::before{
            content: "";
            position: absolute;
            top: 46.5em;
            //bottom: 100px; 
            left: 0;
            width: 100%; 
            height:340px;
            background-repeat: no-repeat;
            background-size: 540px 240px;
            background-position: center;
            opacity: .09; 
            z-index: 1;
            background-image: url('../../assets/images/DSD_logo.jpeg');
        }
    }
   
    .head{
        .logo{
            width: 72px;
            img{
                width: 100%;
            } 
            margin-bottom: 8px;
        }
        .header-1{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .header-1_left,.header-1_right{
                display: flex;
                flex-direction: column;
                gap: 2px;
                margin-bottom: 6px;
                p{
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
        ._header{
            display: flex;
            justify-content: space-between;

            .header-2{
                h3{
                    margin-bottom: 3px;
                    span{
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }
            .header-3{
                .status{
                    .valider{
                        color: green!important;
                        font-size: medium;
                        font-weight: 600;
                    }
                    .annuler{
                        color: red!important;
                        font-size: medium;
                        font-weight: 600;
                    }
                    .default-status{
                        color: #414040;
                        font-weight: 700;
                        font-size: medium;
                    }
                }
            }

        }
       
    }
    .array{
        width: 100%;
        margin-top: 5px;
        table{
            width: 100%;
            border-collapse: collapse;
            text-align: left;
            thead {
     
                // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                background: rgb(228, 228, 61);
                th {
                  padding: .5em .4em;
                }
              }
            tbody{
                tr{
                    text-align: left;
                    &:nth-child(1){
                        background-color: #f2f3f1;
                    }
                    &:nth-child(2){
                        border-spacing: 2em;
                    }
                   
                }
               
                td{
                    padding: .6em .4em;
                    &:nth-child(3){
                        //text-align: center;
                    }
                    
                }
            }  
            
        }
        .total-montant{
             margin-top: 15px;
             display: flex;
             justify-content: end;
            p {
                width: 37%;
                background: rgb(228, 228, 61);
                text-align: center;
                padding: 10px 20px;
                font-weight: 700;
                &:last-child{
                    text-align: end;
                }
            }
        }
    }
    .signature{
        display: flex;
        margin-top: 2px;
        justify-content: space-between;
     
        p{
            font-weight: 600;
        }
    }
    .invoice-footer {
        margin-top: 2.3em;
        padding-top: 1em;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        text-align: center;
        // margin-bottom: 0;
   
       

       .line{
          width: 100%;
          border: 1px dotted #000;
          margin-top: 10px;
          margin-bottom: 8px;
       }

        p {
        //   &:last-child {
            margin-top: .8em;
           
        //   }
        }
        a {
          color: #1f78b4;
        }
    }
}
@media print {
    @page {
        size: A4;
    }
  }