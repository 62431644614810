.step-component{
    .total{
      display: flex;  
      justify-content: space-between;
      margin-top: 10px; 
      .chiffre{
         display: flex;
         gap: 10px;
      }
    }
}