.step-component {
  padding: 2em 2em;
  background-color: $light-grey;
  h4 {
    margin-bottom: 1.5em;
  }

  .buttons {
    margin-top: 1em;
    display: flex;
    justify-content: flex-end;
    .secondary {
      margin-right: 0.5em;
    }
  }

  .input-group,
  .info-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1em;
    margin-bottom: 1.2em;
  }

  .info-group {
    border-bottom: none;
    > div {
      display: flex;
      flex-direction: column;
      span {
        font-size: 0.8rem;
        font-weight: 500;
        color: $dark-grey;
      }
      strong {
        font-size: 0.95rem;
      }
    }
  }

  &.step-two {
    .total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 1.5em;
      padding-top: 0.5em;
      span {
        font-size: $text-sm;
      }
    }
  }  
}
