$primary : #ff8d13;
// $primary : #A1F3B4;
$light-green : #76c893;
$green : #019474;
$white : #fff;
$lighter-grey : #f9f9f9;
$light-grey : #f0f5f7;
$dark-grey : #8d99ae;
$darker-grey : #2b2d42;
$black: #000;
$red: #e63946;
$brown: #e76f51;
$yellow: #e9c46a;
$night-blue: #023e8a;

$flag-red: #ce1126;
$flag-yellow: #fcd116;
$flag-green: #009460;