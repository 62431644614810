.paiement-listdouane{
   .file-uplodader-container{
        display: flex;
        align-items: center;
        gap: 5px;
        .total-data{
            color: #8e8c8c;
            margin-top: 10px;
            font-weight: 600;
            
        }
        input[type="file"] {
            display: none;
            
        } 
        ._disabled{
            pointer-events: none;
            
        }
        .custom-file-upload,._btn {
            color: #8e8c8c;
            border: 1px solid #ccc;
            display: inline-block;
            padding: 6px 12px;
            margin-top: 10px;
            cursor: pointer;
            transition: all .2s ease-in;
            &:hover{
                background: #2b2b01;
                color: white;
                border: 0;
            }
        }
    }
}